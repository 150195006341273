// src/hooks/useParallax.ts
import { useEffect } from 'react';

const useParallax = (elementId: string) => {
    useEffect(() => {
        const handleScroll = () => {
            const element = document.getElementById(elementId);
            if (element) {
                const offset = window.pageYOffset;
                element.style.backgroundPositionY = `${offset * 0.05}px`; // Adjust the multiplier as needed
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [elementId]);
};

export default useParallax;
