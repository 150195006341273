// src/contexts/AuthContext.tsx
import React, { createContext, useState, useContext, ReactNode, useMemo, useEffect } from 'react';
import { authService } from '../services/authService';
import { parseJwt } from '../utils/jwtUtils';
import { ContextUser, TokenUser } from '../types/types';
import {
    mapTokenUserToContextUser,
    updateContextUserWithNewImageFirstLastName
} from '../utils/userMapper';

export interface AuthContextType {
    user: ContextUser | null;
    isAuthenticated: boolean;
    updateUserContextInAC: (userData: ContextUser) => void;
    updateUserContext: (token: string) => Promise<void>;
    updateUserImagePostUpload: () => void;
    updateUserDataPostEdit: () => void;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<ContextUser | null>(() => {
        const token = localStorage.getItem('jwtToken');
        return token ? mapTokenUserToContextUser(parseJwt(token)) : null;
    });

    const [isAuthenticated, setIsAuthenticated] = useState(user !== null);

    const updateUserContext = async (token: string): Promise<void> => {
        const decodedUser: TokenUser = parseJwt(token);
        if (!decodedUser.userId) {
            throw new Error('User ID not found in token');
        }

        const contextUser = mapTokenUserToContextUser(decodedUser);
        updateUserContextInAC(contextUser);
    };

    const updateUserImagePostUpload = () => {
        if (user) {
            const updatedUser = updateContextUserWithNewImageFirstLastName(user);
            updateUserContextInAC(updatedUser);
        }
    };

    const updateUserDataPostEdit = () => {
        if (user) {
            const updatedUser = updateContextUserWithNewImageFirstLastName(user);
            updateUserContextInAC(updatedUser);
        }
    };

    const updateUserImage = (imageUrl: string) => {
        setUser(prevUser => {
            return prevUser ? { ...prevUser, userImageUrl: imageUrl } : null;
        });
    };

    const updateUserContextInAC = (userData: ContextUser) => {
        setUser(userData);
        setIsAuthenticated(true);
    };

    const logout = () => {
        authService.logout();
        localStorage.removeItem('jwtToken');
        // Delete the 'CookieDTO' cookie
        document.cookie = 'CookieDTO=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        setUser(null);
        setIsAuthenticated(false);
    };

    const contextValue = useMemo(() => ({
        user,
        isAuthenticated,
        updateUserImagePostUpload,
        updateUserDataPostEdit,
        updateUserImage,
        updateUserContext,
        updateUserContextInAC,
        logout
    }), [user, isAuthenticated]);

    // Function to check if the token is expired
    const isTokenExpired = (token: string): boolean => {
        const decodedToken: TokenUser = parseJwt(token);
        const tokenExp = Number(decodedToken.exp);
        const currentTime = Math.floor(Date.now() / 1000);
        return tokenExp < currentTime;
    };

    // Periodically check for token expiration
    useEffect(() => {
        const interval = setInterval(() => {
            const token = localStorage.getItem('jwtToken');
            if (token) {
                if (isTokenExpired(token)) {
                    logout();
                }
            }
        }, 60000); // Check every 60 seconds

        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);
