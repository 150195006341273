// src/services/axiosConfig.js
import axios from 'axios';

const getToken = () => localStorage.getItem('jwtToken');

const DEFAULT_TIMEOUT = 20000;

// Base Axios Instance with common configuration
const axiosBase = axios.create({
    withCredentials: true,
    timeout: DEFAULT_TIMEOUT,
});

// Axios Instance with Interceptor
const axiosWithInterceptor = axios.create({
    withCredentials: true,
    timeout: DEFAULT_TIMEOUT,
});

axiosWithInterceptor.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        if (!(config.data instanceof FormData)) {
            config.headers['Content-Type'] = 'application/json';
        }
    } else {
        delete config.headers.Authorization;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export { axiosBase, axiosWithInterceptor };