// src/suite_features/epo_calendar/hooks/useFetchHolidays.ts
import { useState, useEffect, useCallback } from 'react';
import opsService from '../../../business/services/opsService';
import { EpoHolidayQueryRangeDTO, EpoHolidayDTO } from '../../../business/models/EpoHolidayInterfaces';

const useFetchHolidays = (currentMonthIndex: number) => {
    const [holidays, setHolidays] = useState<EpoHolidayDTO[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const fetchHolidays = useCallback(async (startYear: number, startMonth: number, endYear: number, endMonth: number) => {
        setLoading(true);
        setError(null);
        try {
            const queryRange: EpoHolidayQueryRangeDTO = { startYear, startMonth, endYear, endMonth };
            const data = await opsService.fetchEpoHolidayDataByYearAndMonth(queryRange);
            setHolidays(data);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('An unexpected error occurred');
            }
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const currentYear = Math.floor(currentMonthIndex / 12) + 2023;
        const currentMonth = currentMonthIndex % 12 + 1;
        const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
        const nextYear = currentMonth === 12 ? currentYear + 1 : currentYear;

        fetchHolidays(currentYear, currentMonth, nextYear, nextMonth);
    }, [currentMonthIndex, fetchHolidays]);

    return { holidays, loading, error };
};

export default useFetchHolidays;