//src/pages/PricingPage.tsx

import React from 'react';
import Pricing from '../components/Pricing';


const PricingPage: React.FC = () => {
  return (
    <Pricing />
  );
};

export default PricingPage;