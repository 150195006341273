// src/themes/CustomLabel.tsx
import React from 'react';
import { Label } from 'flowbite-react';

// Define the props if needed, you can extend this based on your requirements
interface CustomLabelProps {
  children: React.ReactNode; //children are used to render the label text. React.ReactNode is a type that accepts any valid React node, including strings, numbers, null, undefined, and React elements.
  htmlFor?: string; // Add htmlFor prop
}

const CustomLabel: React.FC<CustomLabelProps> = ({ children, ...props }) => {
  return (
    <Label
      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      {...props}
    >
      {children}
    </Label>
  );
};

export default CustomLabel;