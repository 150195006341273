// src/hooks/useCalendar.ts
import { useMemo } from 'react';

interface Day {
    date: string;
    isCurrentMonth: boolean;
    isToday: boolean;
}

interface Month {
    name: string;
    days: Day[];
}

const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const useCalendar = (): Month[] => {
    return useMemo(() => {
        const months: Month[] = [];
        const startYear = 2023; // Start from 2023
        const startMonth = 0; // Start from January 2023

        for (let i = 0; i < 36; i++) { // Generate 36 months
            const monthIndex = (startMonth + i) % 12;
            const year = startYear + Math.floor((startMonth + i) / 12);
            const monthName = monthNames[monthIndex];
            const days: Day[] = [];

            // Get the number of days in the month
            const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();

            // Get the first day of the month (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
            const firstDayOfMonth = new Date(year, monthIndex, 1).getDay();

            // Fill in the days of the previous month
            const prevMonthDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
            for (let j = prevMonthDays; j > 0; j--) {
                const date = new Date(year, monthIndex, -j + 1);
                days.push({
                    date: date.toISOString().split('T')[0],
                    isCurrentMonth: false,
                    isToday: false
                });
            }

            // Fill in the days of the current month
            for (let j = 1; j <= daysInMonth; j++) {
                const date = new Date(year, monthIndex, j);
                days.push({
                    date: date.toISOString().split('T')[0],
                    isCurrentMonth: true,
                    isToday: date.getFullYear() === new Date().getFullYear() &&
                             date.getMonth() === new Date().getMonth() &&
                             date.getDate() === new Date().getDate()
                });
            }

            // Fill in the days of the next month
            const remainingDays = 42 - days.length; // Ensure the calendar has 6 weeks
            for (let j = 1; j <= remainingDays; j++) {
                const date = new Date(year, monthIndex + 1, j);
                days.push({
                    date: date.toISOString().split('T')[0],
                    isCurrentMonth: false,
                    isToday: false
                });
            }

            months.push({ name: monthName, days });
        }

        return months;
    }, []);
};

export default useCalendar;