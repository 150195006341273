import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

export interface InvoiceData {
    number: string;
    date: string;
    customer: string;
    logo: string;
    numberPosition: { x: number; y: number; };
    datePosition: { x: number; y: number; };
    customerPosition: { x: number; y: number; };
    logoPosition: { x: number; y: number; };
}

const useCreateInvoice = async (invoiceData: InvoiceData) => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([612, 792]); // US Letter size in points
    const { height } = page.getSize();

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    page.drawText('Invoice', {
        x: 50,
        y: height - 50,
        size: 30,
        font,
        color: rgb(0, 0, 0),
    });

    page.drawText(`Invoice Number: ${invoiceData.number}`, {
        x: invoiceData.numberPosition.x,
        y: height - invoiceData.numberPosition.y,
        size: 15,
        font,
        color: rgb(0, 0, 0),
    });

    page.drawText(`Date: ${invoiceData.date}`, {
        x: invoiceData.datePosition.x,
        y: height - invoiceData.datePosition.y,
        size: 15,
        font,
        color: rgb(0, 0, 0),
    });

    page.drawText(`Customer: ${invoiceData.customer}`, {
        x: invoiceData.customerPosition.x,
        y: height - invoiceData.customerPosition.y,
        size: 15,
        font,
        color: rgb(0, 0, 0),
    });

    if (invoiceData.logo) {
        const logoImage = await pdfDoc.embedPng(invoiceData.logo);
        page.drawImage(logoImage, {
            x: invoiceData.logoPosition.x,
            y: height - invoiceData.logoPosition.y,
            width: 100,
            height: 100,
        });
    }

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
};

export default useCreateInvoice;
