//src/business/services/opsService.ts
import axios from 'axios';
import { EpRegisterDTO, PublishedDataDTO } from '../models/EpRegisterInterfaces';
import { axiosWithInterceptor } from '../../services/axiosConfig';
import config from '../../config/config';
import { DashboardResponse  } from '../models/FamilyTreeInterfaces';
import { EpoHolidayQueryRangeDTO } from '../models/EpoHolidayInterfaces';

export interface PastEpRegisterQueriesDTO {
  id?: string;
  applicationNr?: string;
  requestTime?: string;
  queryRef: string;
  rowCount: number;
  totalItemcount: number;
  categoryNames: string[];
}

const { 
  SERVER_BASE_URL, 
  SERVER_API_EPRECORD, 
  SERVER_API_EPRECORD_BY_APPLICATION_NUMBER, 
  SERVER_API_EPRECORD_PAST_QUERIES,
  SERVER_API_EPRECORD_DELETE_PAST_QUERY,
  SERVER_API_EP_GANTT,
  SERVER_D_F_APPLICATION,
  SERVER_D_F_PUBLICATION,
  SERVER_API_EPO_CALENDAR
} = config;

const fetchEpoHolidayDataByYearAndMonth = async (queryRange: EpoHolidayQueryRangeDTO): Promise<any> => {
  try {
    const response = await axiosWithInterceptor.get(
      `${SERVER_BASE_URL}${SERVER_API_EPO_CALENDAR}`, 
      { params: queryRange } // Use params for GET request
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Fetching epo holiday data failed');
    }
    throw error;
  }
};

const fetchEpRegisterDataByApplicationNr = async (applicationNr: string): Promise<EpRegisterDTO> => {
  try {
    const response = await axiosWithInterceptor.get<EpRegisterDTO>(
      `${SERVER_BASE_URL}${SERVER_API_EPRECORD}${SERVER_API_EPRECORD_BY_APPLICATION_NUMBER}`, 
      { 
        params: { applicationNr } // Sending 'applicationNr' as a query parameter
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Fetching ep register data failed');
    }
    throw error;
  }
};


const fetchGlobalDataByApplicationNr = async (applicationNumber: string): Promise<PublishedDataDTO> => {
  try {
    const response = await axiosWithInterceptor.get<PublishedDataDTO>(
      `${SERVER_BASE_URL}${SERVER_API_EPRECORD}${SERVER_D_F_APPLICATION}`, 
      { 
        params: { applicationNumber } // Sending 'applicationNr' as a query parameter
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Fetching ep register data failed');
    }
    throw error;
  }
};

export interface PublicationDataDto {
  applicationRef: string;
  kind: string;
}

const fetchGlobalDataByPublicationNr = async (publicationData: PublicationDataDto): Promise<PublishedDataDTO> => {
  try {
    const response = await axiosWithInterceptor.post<PublishedDataDTO>(
      `${SERVER_BASE_URL}${SERVER_API_EPRECORD}${SERVER_D_F_PUBLICATION}`, 
      publicationData
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Fetching ep register data failed');
    }
    throw error;
  }
};

const fetchPastEpRegisterQueries = async (): Promise<PastEpRegisterQueriesDTO[]> => {
  try {
    const response = await axiosWithInterceptor.get<PastEpRegisterQueriesDTO[]>(
      `${SERVER_BASE_URL}${SERVER_API_EPRECORD}${SERVER_API_EPRECORD_PAST_QUERIES}`,       
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Fetching past ep register queries failed');
    }
    throw error;
  }
};

const deleteEpRegisterQuery = async (queryId: string): Promise<string> => {
  try {
    const response = await axiosWithInterceptor.delete(
      `${SERVER_BASE_URL}${SERVER_API_EPRECORD}${SERVER_API_EPRECORD_DELETE_PAST_QUERY}?id=${queryId}`,       
    );
    return response.data.message || 'Query deleted successfully';
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Deleting past ep register query failed');
    }
    throw error;
  }
};

const fetchGanttChartByApplicationNr = async (applicationNumber: string): Promise<DashboardResponse> => {
  try {
    // URL encode the applicationNumber for the URL path
    const encodedApplicationNumber = encodeURIComponent(applicationNumber);

    const response = await axiosWithInterceptor.get<DashboardResponse>(
      `${SERVER_BASE_URL}${SERVER_API_EP_GANTT}${encodedApplicationNumber}`, 
      { 
        params: { applicationNumber: encodedApplicationNumber }
      }
    );
    // Return the response data as DashboardResponse
    return response.data; 
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Fetching ep register data failed');
    }
    throw error;
  }
};

export default {
  fetchEpRegisterDataByApplicationNr,
  fetchPastEpRegisterQueries,
  deleteEpRegisterQuery,
  fetchGanttChartByApplicationNr,
  fetchGlobalDataByApplicationNr,
  fetchGlobalDataByPublicationNr,
  fetchEpoHolidayDataByYearAndMonth
};

