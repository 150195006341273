//src/pages/ChangeLog.tsx
import React from 'react';
import { Timeline } from 'flowbite-react';
import "./../index.css"
import { Link } from 'react-router-dom';
import { Button } from 'flowbite-react';
import PoweredByRibbon from '../htmlBlocks/PoweredByRibbon';
const ChangeLog: React.FC = () => {
  return (
    <div>
      <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16">
        <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center">

          <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-6xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
            Site News and Updates
          </h1>
          <div className='flex justify-center'>
            <Timeline className='text-start'>
              <Timeline.Item>
                <Timeline.Point />
                <Timeline.Content>
                  <Timeline.Time>
                    Dec 13, 2023
                  </Timeline.Time>
                  <Timeline.Title>
                    Initial Launch
                  </Timeline.Title>
                  <Timeline.Body>
                    First version of the site is live
                  </Timeline.Body>
                </Timeline.Content>
              </Timeline.Item>
              <Timeline.Item>
                <Timeline.Point />
                <Timeline.Content>
                  <Timeline.Time>
                    July 25, 2024
                  </Timeline.Time>
                  <Timeline.Title>
                    V1.0.1
                  </Timeline.Title>
                  <Timeline.Body>
                    Iframe search added. Use the following code to embed the search iframe:
                    <div className='responsive-pre'>
                      <code>
                        {`<iframe id="searchIframe" width="100%" height="1200" style="border:0;" src="https://patalytica.com/family-tree-search-iframe"></iframe>`}
                      </code>
                    </div>
                  </Timeline.Body>
                </Timeline.Content>
              </Timeline.Item>
              <Timeline.Item>
                <Timeline.Point />
                <Timeline.Content>
                  <Timeline.Time>
                    July 28, 2024
                  </Timeline.Time>
                  <Timeline.Title>
                    V1.0.2 - WIPO Link
                  </Timeline.Title>
                  <Timeline.Body>
                    All international applications now have a link to the WIPO page. Simply click on the international application (purple) or publication (blue) tile and click on the "Go to WIPO register WO..." button. Try it out with the following link:
                    <Button
                      size="md"
                      type="submit"
                      gradientDuoTone="pinkToOrange"
                    >
                      <Link to={`https://patalytica.com/power-dashboard/PCTUS2022030594`}>
                        Try it
                      </Link>
                    </Button>
                    <p>Could you find it?</p>
                  </Timeline.Body>
                </Timeline.Content>
              </Timeline.Item>
              <Timeline.Item>
                <Timeline.Point />
                <Timeline.Content>
                  <Timeline.Time>
                    August 4, 2024
                  </Timeline.Time>
                  <Timeline.Title>
                    V1.0.3
                  </Timeline.Title>
                  <Timeline.Body>
                    Iframe for EPO Closing days added. Use the following code to embed the search iframe:
                    <div className='responsive-pre'>
                      <code>
                        {`<iframe id="searchIframe" width="100%" height="1200" style="border:0;" src="https://patalytica.com/epo-calendar-iframe"></iframe>`}
                      </code>
                    </div>
                    <div className='mt-8 bg-gray-100 dark:bg-black relative w-64 py-16 border-2 border-radius-4 dark:border-neutral-800'>
                      <PoweredByRibbon />
                      <p className='p-4'>Also created this ribbon</p>
                    </div>
                  </Timeline.Body>
                </Timeline.Content>
              </Timeline.Item>
            </Timeline>
          </div>
        </div>
      </section>
    </div>

  );
};

export default ChangeLog;


{/* <Accordion className="border-0 divide-y-0">
  <Accordion.Panel>
    <Accordion.Title className='slow-arrow-rotation shadow-md rounded-lg hover:scale-101 transition-transform duration-150 bg-gray-50 hover:bg-gray-50 focus:ring-0'>
      Accordion Item #1
    </Accordion.Title>
    <Accordion.Content className='slide-in shadow-md hover:scale-101 border-white'>
      Access hundreds of IP analytics tools and systems
    </Accordion.Content>
  </Accordion.Panel>
  <Accordion.Panel>
    <Accordion.Title className='mt-4 slow-arrow-rotation shadow-md rounded-lg hover:scale-101 transition-transform duration-150 bg-gray-50 hover:bg-gray-50 focus:ring-0'>
      Accordion Item #2
    </Accordion.Title>
    <Accordion.Content className='slide-in shadow-md hover:scale-101 border-white'>
      This is the first item's accordion body.
    </Accordion.Content>
  </Accordion.Panel>
</Accordion> */}
