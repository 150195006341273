// src/utils/jwtUtils.ts
import { TokenUser } from '../types/types';

export function parseJwt(token: string): TokenUser {
    if (!token || token.trim() === '') {
        throw new Error('Please confirm your email to get access.');
    }
    try {
        const base64Url = token.split('.')[1]; // Get the payload part
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );

        const decoded = JSON.parse(jsonPayload);
        // Ensure roles is always an array
        const roles = Array.isArray(decoded.roles) ? decoded.roles : [decoded.roles];

        // Map the `uid` from the token to `userId` in the User interface
        const parsedTokenUser: TokenUser = {
            userId: decoded.uid,
            userName: decoded.sub,
            jti: decoded.jti,
            email: decoded.email,
            ip: decoded.ip,
            isProUser: decoded.IsProUser,
            isFreeTrialUser: decoded.IsFreeTrialUser,
            freeTrialExpiryDate: decoded.FreeTrialExpiryDate,
            hasStartedFreeTrial: decoded.HasStartedFreeTrial,
            roles: roles,
            exp: decoded.exp,
            iss: decoded.iss,
            aud: decoded.aud
        };

        // Log the parsed token user data
        // console.log('Parsed JWT Token:', parsedTokenUser);

        return parsedTokenUser;
    } catch (error) {
        console.error('Error decoding token:', error);
        throw error;
    }
}
