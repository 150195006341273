// src/services/paymentService.ts
import axios from 'axios';
import config from '../config/config';
import { axiosBase } from './axiosConfig';

const { SERVER_BASE_URL, SERVER_API_STRIPE } = config;

const createPaymentIntent = async (
    amount: number,
    email: string,
    productId: string,
    paymentMethodId: string // Add PaymentMethodId as a parameter
  ) => {
    try {
      const response = await axiosBase.post(`${SERVER_BASE_URL}${SERVER_API_STRIPE}create-payment-intent`, {
        amount,
        email,
        productId,
        paymentMethodId, // Include PaymentMethodId in the payload
      });
  
      return { clientSecret: response.data.clientSecret, error: null };
    } catch (error: unknown) {
      console.error('Error in createPaymentIntent:', error);
  
      if (axios.isAxiosError(error)) {
        // Axios specific error
        if (error.response) {
          console.error('Server responded with:', error.response.data);
          return { clientSecret: null, error: error.response.data };
        } else {
          return { clientSecret: null, error: 'An unknown error occurred.' };
        }
      } else {
        // Generic or unexpected error
        return { clientSecret: null, error: 'An unexpected error occurred.' };
      }
    }
  };

  const createSubscription = async (
  email: string,
  priceLookupKey: string,
  paymentMethodId: string,
  firstName: string,
  lastName: string
) => {
  try {
    const response = await axiosBase.post(
      `${SERVER_BASE_URL}${SERVER_API_STRIPE}create-subscription`,
      {
        email,
        priceLookupKey,
        paymentMethodId,
        firstName,
        lastName,
      }
    );

    // Return clientSecret, onboardingToken, and no error
    return {
      clientSecret: response.data.clientSecret,
      onboardingToken: response.data.onboardingToken,
      error: null,
    };
  } catch (error: unknown) {
    console.error("Error in createSubscription:", error);

    if (axios.isAxiosError(error)) {
      // Axios specific error
      if (error.response) {
        console.error("Server responded with:", error.response.data);
        return {
          clientSecret: null,
          onboardingToken: null,
          error: error.response.data,
        };
      } else {
        return {
          clientSecret: null,
          onboardingToken: null,
          error: "An unknown error occurred.",
        };
      }
    } else {
      // Generic or unexpected error
      return {
        clientSecret: null,
        onboardingToken: null,
        error: "An unexpected error occurred.",
      };
    }
  }
};
  
  const fetchSubscriptionPrice = async (subscriptionId: string) => {
    try {
        const response = await axiosBase.get(`${SERVER_BASE_URL}${SERVER_API_STRIPE}get-prices/${subscriptionId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching subscription price for ${subscriptionId}:`, error);
        
        if (axios.isAxiosError(error)) {
            // Axios-specific error handling
            return error.response ? error.response.data : 'An unknown error occurred.';
        } else {
            // Generic error
            return 'An unexpected error occurred.';
        }
    }
};

const fetchProduct = async (priceLookupKey: string) => {
    try {
        const response = await axiosBase.get(`${SERVER_BASE_URL}${SERVER_API_STRIPE}get-product/${priceLookupKey}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching product:', error);
        throw error;
    }
};

export default {
    createPaymentIntent,
    createSubscription,
    fetchSubscriptionPrice,
    fetchProduct
};