// src/htmlBlocks/PoweredByRibbon.tsx
import React from "react";

const PoweredByRibbon: React.FC = () => {
  return (
    <a href="https://patalytica.com" target="_blank" rel="noopener" className="branding-link">
      <div data-id="branding" className="branding-container bg-purple-800 hover:bg-purple-700">
        <div className="branding-text">powered by</div>
        <div className="branding-logo text-white">Patalytica</div>
      </div>
    </a>
  );
};

export default PoweredByRibbon;