//src/pages/PrivacyPolicy.tsx
import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div>
      <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16">
        <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center">
          <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-6xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
          PATALYTICA PRIVACY POLICY
          </h1>
        </div>
        <p className="mb-4">
    We take your privacy very seriously. Please read this privacy policy carefully as it contains important information on who we are, how and why we collect, store, use, and share your personal information.
  </p>

  <h2 className="text-xl font-semibold mb-2">1. Key Terms</h2>
  <table className="w-full text-sm text-left text-gray-500 mb-6">
    <thead className="text-xs text-gray-700 uppercase bg-gray-200">
      <tr>
        <th className="px-4 py-2">Term</th>
        <th className="px-4 py-2">Definition</th>
      </tr>
    </thead>
    <tbody>
      <tr className="bg-white border-b">
        <td className="px-4 py-2">We, Us, Our</td>
        <td className="px-4 py-2">IP Confidential, LLC., a FL corporation, 7000 West Palmetto Park Rd, Ste 210, Boca Raton, FL</td>
      </tr>
      <tr className="bg-gray-50 border-b">
        <td className="px-4 py-2">You, Your</td>
        <td className="px-4 py-2">The user accessing patalytica.com</td>
      </tr>
      <tr className="bg-white border-b">
        <td className="px-4 py-2">Personal Information</td>
        <td className="px-4 py-2">Any information relating to an identified or identifiable individual</td>
      </tr>
    </tbody>
  </table>

  <h2 className="text-xl font-semibold mb-2">2. Personal Information We Collect</h2>
  <ul className="list-disc pl-6 mb-4">
    <li className="mb-2">Identifiers: First and last name, shipping address, email address, phone number, etc.</li>
    <li className="mb-2">Mobile application-specific identifiers from your mobile device.</li>
    <li className="mb-2">Multimedia uploaded for reviews or customer service requests.</li>
    <li className="mb-2">Financial information such as credit card or debit card details for payment processing.</li>
  </ul>

  <div className="mt-4 text-gray-500">
    <span>CONTINUED ON THE FOLLOWING PAGE</span>
  </div>
        
      </section>
    </div>
  );
};

export default PrivacyPolicy;


{/* <Accordion className="border-0 divide-y-0">
  <Accordion.Panel>
    <Accordion.Title className='slow-arrow-rotation shadow-md rounded-lg hover:scale-101 transition-transform duration-150 bg-gray-50 hover:bg-gray-50 focus:ring-0'>
      Accordion Item #1
    </Accordion.Title>
    <Accordion.Content className='slide-in shadow-md hover:scale-101 border-white'>
      Access hundreds of IP analytics tools and systems
    </Accordion.Content>
  </Accordion.Panel>
  <Accordion.Panel>
    <Accordion.Title className='mt-4 slow-arrow-rotation shadow-md rounded-lg hover:scale-101 transition-transform duration-150 bg-gray-50 hover:bg-gray-50 focus:ring-0'>
      Accordion Item #2
    </Accordion.Title>
    <Accordion.Content className='slide-in shadow-md hover:scale-101 border-white'>
      This is the first item's accordion body.
    </Accordion.Content>
  </Accordion.Panel>
</Accordion> */}
