// src/pages/InvoiceGenerator.tsx
import React, { useState, useEffect } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAuthContext } from '../contexts/AuthContext';
// hooks
import useCreateInvoice from '../hooks/useCreateInvoice';
// interfaces
import { InvoiceData } from '../hooks/useCreateInvoice';

const ItemTypes = {
    FIELD: 'field',
};

interface DragItem {
    id: string;
    left: number;
    top: number;
}

interface DraggableFieldProps {
    id: string;
    left: number;
    top: number;
    moveField: (id: string, left: number, top: number) => void;
}

const DraggableField: React.FC<React.PropsWithChildren<DraggableFieldProps>> = ({ id, left, top, children, moveField }) => {
    const [, ref] = useDrag({
        type: ItemTypes.FIELD,
        item: { id, left, top },
    });

    const [, drop] = useDrop({
        accept: ItemTypes.FIELD,
        drop: (item: DragItem, monitor) => {
            const delta = monitor.getDifferenceFromInitialOffset();
            if (delta) {
                const x = Math.round(item.left + delta.x);
                const y = Math.round(item.top + delta.y);
                moveField(item.id, x, y);
            }
        },
    });

    return (
        <div ref={(node) => ref(drop(node))} style={{ position: 'absolute', left, top }}>
            {children}
        </div>
    );
};

const downloadPDF = (pdfBytes: Uint8Array) => {
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `template-${new Date().toISOString().split('T')[0]}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const InvoiceGenerator: React.FC = () => {
    const [invoiceData, setInvoiceData] = useState<InvoiceData>({
        number: '',
        date: '',
        customer: '',
        logo: '',
        numberPosition: { x: 50, y: 100 },
        datePosition: { x: 50, y: 130 },
        customerPosition: { x: 50, y: 160 },
        logoPosition: { x: 50, y: 190 },
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInvoiceData({ ...invoiceData, [name]: value });
    };

    const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    setInvoiceData((prevState) => ({
                        ...prevState,
                        logo: e.target?.result as string,
                    }));
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const pdfBytes = await useCreateInvoice(invoiceData);
        downloadPDF(pdfBytes);
    };

    const moveField = (id: string, left: number, top: number) => {
        setInvoiceData((prevState) => ({
            ...prevState,
            [`${id}Position`]: { x: left, y: top },
        }));
    };

    const authContext = useAuthContext();
    if (!authContext) {
        alert('authContext is null');
        return null;
    }
    const { user } = authContext;

    useEffect(() => {
        if (!user) {
            alert('Please login to access this page');
        }
    }, [user]);
    
    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                <section className="py-8 px-4 bg-gray-50 dark:bg-black mx-auto sm:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-3xl text-center my-32">
                        <h1 className="mt-16 mb-4 font-normal text-gray-900 dark:text-white text-5xl lg:text-6xl xl:px-36 lg:mb-7">
                            Welcome to the Patalytica Invoice Generator
                        </h1>
                    </div>
                    <div className="mx-auto max-w-screen-md text-center">
                        <h2 className="mt-10 mb-8 tracking-tight font-semibold text-gray-900 dark:text-white text-2xl md:text-4xl lg:text-5xl">
                            <div className="w-full p-4">
                                <h2 className="mb-6 font-normal text-gray-900 dark:text-white text-4xl lg:text-5xl">
                                    Dynamically generate invoices for your law firm.
                                </h2>
                            </div>
                        </h2>
                        <form className="mt-10 relative bg-white dark:bg-white" style={{ width: '612px', height: '792px', border: '1px solid black' }}>
                            <DraggableField id="number" left={invoiceData.numberPosition.x} top={invoiceData.numberPosition.y} moveField={moveField}>
                                <div className="mb-4">
                                    <label htmlFor="number">
                                        Invoice Number
                                    </label>
                                    <input
                                        type="text"
                                        name="number"
                                        id="number"
                                        value={invoiceData.number}
                                        onChange={handleChange}
                                    />
                                </div>
                            </DraggableField>
                            <DraggableField id="date" left={invoiceData.datePosition.x} top={invoiceData.datePosition.y} moveField={moveField}>
                                <div className="mb-4">
                                    <label  htmlFor="date">
                                        Date
                                    </label>
                                    <input
                                        type="date"
                                        name="date"
                                        id="date"
                                        value={invoiceData.date}
                                        onChange={handleChange}
                                    />
                                </div>
                            </DraggableField>
                            <DraggableField id="customer" left={invoiceData.customerPosition.x} top={invoiceData.customerPosition.y} moveField={moveField}>
                                <div className="mb-4">
                                    <label htmlFor="customer">
                                        Customer Name
                                    </label>
                                    <input
                                        type="text"
                                        name="customer"
                                        id="customer"
                                        value={invoiceData.customer}
                                        onChange={handleChange}
                                    />
                                </div>
                            </DraggableField>
                            <DraggableField id="logo" left={invoiceData.logoPosition.x} top={invoiceData.logoPosition.y} moveField={moveField}>
                                <div className="mb-4">
                                    <label htmlFor="logo">
                                        Company Logo
                                    </label>
                                    <input
                                        type="file"
                                        name="logo"
                                        id="logo"
                                        accept="image/*"
                                        onChange={handleLogoChange}
                                    />
                                    {invoiceData.logo && <img src={invoiceData.logo} alt="Logo Preview" style={{ maxWidth: '50px', maxHeight: '50px' }} />}
                                </div>
                            </DraggableField>
                        </form>
                        <button
                            onClick={handleSubmit}
                            className="mt-4 px-6 py-3 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none"
                        >
                            Generate Invoice
                        </button>
                    </div>
                </section>
            </div>
        </DndProvider>
    );
};

export default InvoiceGenerator;
