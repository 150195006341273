// src/components/Pricing.tsx
import React from 'react';
import { Helmet } from 'react-helmet';
import PricingHtml from './PricingHtml';
import PricingBlock from './PricingBlock';

const Pricing: React.FC = () => {
    return (
        <div>
            <Helmet>
                <title>Pricing page for Patalytica</title>
                <meta name="description" content="This page shows the pricing options for Patalytica" />
                <meta property="og:title" content="Pricing options for Patalytica" />
                <meta property="og:description" content="Pricing tiers for Patalytica" />
            </Helmet>
            <section className="py-8 bg-white dark:bg-gray-900 lg:py-24" id="Page">
                <div className="px-4 mx-auto max-w-8xl lg:px-4">
                    <h2 className="mb-4 text-3xl font-bold text-gray-900 lg:font-extrabold lg:text-4xl lg:leading-snug dark:text-white lg:text-center 2xl:px-48">Choose the right plan for your business
                    </h2>
                    <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl lg:mb-16">Used by freelancers, startups, companies, and enterprise-level corporations all over the world.
                    </p>
                    <PricingBlock/>
                    <PricingHtml/>
                    </div>
            </section>
        </div>
    );
};

export default Pricing;