//src/services/blogService.ts

import { axiosBase } from './axiosConfig';
import config from '../config/config';
import axios from 'axios';


const { 
  SERVER_BASE_URL, 
  SERVER_API_WORDPRESS, 
  SERVER_API_SIGNLE_BLOG_POST_BY_SLUG,  
} = config;

export interface BlogPostPreviewDTO {
    id: number;
    date: Date;
    authorName: string;
    authorImageUrl: string;
    excerpt: string;
    altText: string;
    title: string;
    slug: string;
    featuredMediaUrl: string;
    categoryNames: string[];
    tags: string[];
}

export interface SingleBlogPostDTO {
  id: number,
  metaTitle: string,
  metaDescription: string,
  date: Date,
  dateGmt: Date,
  modified: Date,
  modifiedGmt: Date,
  slug: string,
  altText: string,
  title: string,
  content: string,
  excerpt: string,
  authorName: string,
  authorImageUrl: string,
  authorSocialMediaUrl: string,
  featuredMediaUrl: string,
  commentStatus: string,
  pingStatus: string,
  categoryNames: string[],
  tagNames: string[],
}


const fetchLatestBlogPosts = async () => {
    try {
        const response = await axiosBase.get<BlogPostPreviewDTO[]>(`${SERVER_BASE_URL}${SERVER_API_WORDPRESS}latestBlogPosts`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Fetching all blog posts failed');
        }
        throw error;
    }
};

const fetchLatestThreeBlogPosts = async () => {
  try {
    const response = await axiosBase.get<BlogPostPreviewDTO[]>(`${SERVER_BASE_URL}${SERVER_API_WORDPRESS}latestThreeBlogPosts`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
        throw new Error(error.response.data.message || 'Fetching latest three blog posts failed');
    }
    throw error;
  }
};

const fetchRelevantBlogPosts = async (categoryNames: string[]) => {
  try {
    const response = await axiosBase.post<BlogPostPreviewDTO[]>(`${SERVER_BASE_URL}${SERVER_API_WORDPRESS}relevantBlogPosts`, categoryNames);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
        throw new Error(error.response.data.message || 'Fetching relevant blog posts failed');
    }
    throw error;
  }
};

const fetchSingleBlogPostBySlug = async (slug: string) => {
  try {
    const response = await axiosBase.get<SingleBlogPostDTO>(`${SERVER_BASE_URL}${SERVER_API_SIGNLE_BLOG_POST_BY_SLUG}${slug}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Fetching single blog post failed');
    }
    throw error;
  }
};

const fetchSameCategoryBlogPosts = async (categoryName: string) => {
  try {
    const response = await axiosBase.get<BlogPostPreviewDTO[]>(`${SERVER_BASE_URL}${SERVER_API_WORDPRESS}sameCategoryBlogPosts`, {
      params: {
        categoryName: categoryName
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
        throw new Error(error.response.data.message || 'Fetching same category blog posts failed');
    }
    throw error;
  }
};

export const blogService = {
  fetchLatestBlogPosts,
  fetchSingleBlogPostBySlug,
  fetchRelevantBlogPosts,
  fetchSameCategoryBlogPosts,
  fetchLatestThreeBlogPosts
};