import React, { useState, useEffect } from 'react';
import { TextInput, Button } from 'flowbite-react';
import { useNavigate, useLocation } from 'react-router-dom';
import ErrorAlert from '../htmlBlocks/ErrorAlert';
import CustomLabel from '../themes/CustomLabel';
import PoweredByRibbon from '../htmlBlocks/PoweredByRibbon';

const FamilyTreeSearch: React.FC = () => {
    const [applicationNumberPlain, setApplicationNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const searchString = searchParams.get('query');

        if (searchString) {
            setApplicationNumber(searchString);
            performSearch(searchString);
        }
    }, [location.search]);

    const performSearch = async (applicationNumberPlain: string) => {
        setIsLoading(true);
        setErrorMessage('');
        try {
            // Write code that deletes all "/" and ',' from the applicationNumber
            const applicationNumberNoSlash: string = applicationNumberPlain.replace(/\//g, '');
            const applicationNumberNoComma: string = applicationNumberNoSlash.replace(/,/g, '');
            const applicationNumber = applicationNumberNoComma;

            // Use the encoded application number for navigation
            navigate(`/power-dashboard/${applicationNumber}`);
        } catch (error: unknown) {
            // Error handling logic
            setErrorMessage('An error occurred during the search.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        performSearch(applicationNumberPlain);
    };

    // Function to clear messages
    const clearMessages = () => {
        setErrorMessage('');
    };

    return (
        <div className="flex flex-col justify-center items-center px-6 mx-auto md:h-screen dark:bg-black ">
            <div className="justify-center items-center w-full bg-white lg:flex md:mt-0 lg:max-w-screen-sm xl:p-0 dark:bg-gray-800 dark:shadow-lg dark:showdow-purple-400 my-shadow-sharp rounded-lg relative z-10 bg-white dark:bg-gray-800 hover:dark:border-purple-400 hover:dark:border-2">
                <PoweredByRibbon />
                <div className="p-6 w-full sm:p-8">
                    {errorMessage && (
                        <ErrorAlert
                            errorMessage={errorMessage}
                            clearMessages={clearMessages}
                        />
                    )}
                    <div className="flex justify-center text-center">
                        <h3 className="mb-3 font-normal text-gray-900 dark:text-white text-xl md:text-3xl lg:text-4xl">
                            Patent Family <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-400 from-purple-700">
                                Timeline Visualization
                            </span>
                        </h3>
                    </div>
                    <div className="flex justify-center py-8">
                        <Button
                            size="xl"
                            gradientDuoTone="pinkToOrange"
                            onClick={() => navigate(`/public-dashboard/EP20833423`)}
                            className="my-shadow-sharp"
                        >
                            <span className="flex justify-center items-center text-2xl">
                                {isLoading ? 'Fetching data...' : 'Try EP20833423'}
                            </span>
                        </Button>
                    </div>
                    <div className="flex justify-center pb-8">
                        <Button
                            size="xl"
                            gradientDuoTone="pinkToOrange"
                            onClick={() => navigate(`/public-dashboard/EP20780773`)}
                            className="my-shadow-sharp"
                        >
                            <span className="flex justify-center items-center text-2xl">
                                {isLoading ? 'Fetching data...' : 'Try EP20780773'}
                            </span>
                        </Button>
                    </div>
                    <hr className="border-2 dark:border-purple-700" />
                    <div className="flex justify-center pt-8 text-center">
                        <h3 className="mb-3 font-normal text-gray-900 dark:text-white text-xl md:text-3xl lg:text-4xl">
                            Let's try one of yours? Enter any patent
                            <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-400 from-purple-700"> Application Number</span>:
                        </h3>
                    </div>

                    <div className="flex justify-center pb-4">
                        <form onSubmit={handleSubmit} className="mt-8">
                            <CustomLabel htmlFor="applicationNumber">
                                Patent Application Number
                            </CustomLabel>
                            <div className="grid gap-6 lg:grid-cols-2">
                                <div>
                                    <TextInput
                                        id="applicationNumber"
                                        type="text"
                                        value={applicationNumberPlain}
                                        name="applicationNumber"
                                        onChange={(e) => setApplicationNumber(e.target.value)}
                                        placeholder="e.g. 17/988,733"
                                        required
                                        autoComplete="on"
                                    />
                                </div>
                                <div>
                                    <Button
                                        size="md"
                                        type="submit"
                                        gradientDuoTone="purpleToBlue"
                                        className="my-shadow-sharp"
                                    >
                                        {isLoading && (
                                            <span className="flex justify-center items-center">
                                                <svg
                                                    aria-hidden="true"
                                                    role="status"
                                                    className="inline w-4 h-4 mr-3 text-white animate-spin"
                                                    viewBox="0 0 100 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="#E5E7EB"
                                                    />
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </span>
                                        )}
                                        <span className="flex justify-center items-center">
                                            {isLoading ? 'Fetching data...' : 'Build Patent Timeline'}
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="flex justify-center">
                        <p className="mb-3 mt-4 text-sm text-gray-600 font-normal dark:text-gray-300 text-center">
                            Iphones may struggle with large family trees. Please use a desktop for best experience.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FamilyTreeSearch;