// src/utils/userMapper.ts
import { ContextUser, TokenUser } from '../types/types';
import Cookies from 'js-cookie';

export interface CookieDTO {
    FirstName: string;
    LastName: string;
    UserImageUrl: string;
}

export const mapTokenUserToContextUser = (tokenUser: TokenUser): ContextUser => {
    const cookieValue = Cookies.get('CookieDTO');
    const decodedCookieValue = cookieValue ? decodeURIComponent(decodeURIComponent(cookieValue)) : null;

    let cookieData: CookieDTO = { FirstName: '', LastName: '', UserImageUrl: '' };

    if (decodedCookieValue) {
        try {
            cookieData = JSON.parse(decodedCookieValue) as CookieDTO;
        } catch (error) {
            console.error('Error parsing JSON from cookie', error);
        }
    }
    return {
        firstName: cookieData.FirstName || undefined,
        lastName: cookieData.LastName || undefined,
        userImageUrl: cookieData.UserImageUrl || undefined,
        userId: tokenUser.userId,
        userName: tokenUser.userName,
        jti: tokenUser.jti,
        email: tokenUser.email,
        ip: tokenUser.ip,              
        isProUser: tokenUser.isProUser,
        isFreeTrialUser: tokenUser.isFreeTrialUser,
        freeTrialExpiryDate: tokenUser.freeTrialExpiryDate,
        hasStartedFreeTrial: tokenUser.hasStartedFreeTrial,        
        roles: tokenUser?.roles,
        exp: tokenUser.exp,
        iss: tokenUser.iss,
        aud: tokenUser.aud,        
        userCountry: undefined,
        userOrganization: undefined,
        userRoleInUserOrganization: undefined,
        wantNewsletter: false,
        wantProductUpdates: false,
    };
};

export const updateContextUserWithNewImageFirstLastName = (contextUser: ContextUser): ContextUser => {
    const cookieValue = Cookies.get('CookieDTO');
    const decodedCookieValue = cookieValue ? decodeURIComponent(decodeURIComponent(cookieValue)) : null;

    let cookieData: CookieDTO = { FirstName: '', LastName: '', UserImageUrl: '' };

    if (decodedCookieValue) {
        try {
            cookieData = JSON.parse(decodedCookieValue) as CookieDTO;
        } catch (error) {
            console.error('Error parsing JSON from cookie', error);
        }
    }
    return {
        ...contextUser,
        userImageUrl: cookieData.UserImageUrl || contextUser.userImageUrl,
        firstName: cookieData.FirstName || contextUser.firstName,
        lastName: cookieData.LastName || contextUser.lastName
    };
};
