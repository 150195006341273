// src/services/authService.ts
import { axiosWithInterceptor} from './axiosConfig';
import config from '../config/config';
import axios from 'axios';


const { SERVER_BASE_URL, SERVER_API_AUTH, CLIENT_BASE_URL } = config;

interface LoginData {
    email: string;
    password: string;
    rememberMe: boolean;
}

interface RegisterData {
    firstName: string;
    lastName: string;
    acceptTerms: boolean;
    email: string;
    password: string;
    confirmPassword: string;
}

interface ConfirmEmailData {
    userId: string;
    code: string;
}

interface ForgotPasswordData {
    email: string;
}

interface ResetPasswordData {
    userId: string;
    password: string;
    confirmPassword: string;
    code: string;
}

// Method to save the JWT token
const saveToken = (token: string) => {
    localStorage.setItem('jwtToken', token);
};

// Method to get the JWT token
const getToken = () => {
    return localStorage.getItem('jwtToken');
};

// Updated login method
const loginViaAuthService = async (loginData: LoginData) => {
    try {
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_AUTH}token`, loginData);
        saveToken(response.data.token); // Assuming the token is in the 'token' field
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Login failed');
        }
        throw error;
    }
};

const logout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('additionalUserData');
};

const register = async (registerData: RegisterData) => {
    try {
        const data = { ...registerData, returnUrl: `${CLIENT_BASE_URL}/email-confirmation` };
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_AUTH}register`, data);
        return response.data; // Return the response data, could be a success message or other details
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Registration failed');
        }
        throw error; // Throw the original error if it's not an AxiosError
    }
};

export const confirmEmail = async (confirmEmailData: ConfirmEmailData) => {
    try {
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_AUTH}confirmEmail`, confirmEmailData);
        return response.data; // Return the response data, could be a success message or other details
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Email confirmation failed');
        }
        throw error; // Throw the original error if it's not an AxiosError
    }
};

const resetPasswordEmail = async (forgotPasswordData: ForgotPasswordData) => {
    try {
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_AUTH}forgotPassword`, forgotPasswordData);
        return response.data; // Assuming response.data contains a 'succeeded' property
    }
    catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            // If the error has a response and it's a BadRequest (status 400)
            if (error.response.status === 400 && Array.isArray(error.response.data)) {
                // If the data is an array, it's the error messages
                throw new Error(error.response.data.join(' '));
            }
        }
        throw error; // Re-throw other types of errors
    }
}

export const resetPassword = async (resetPasswordData: ResetPasswordData) => {
    try {
        const response = await axiosWithInterceptor.post(`${SERVER_BASE_URL}${SERVER_API_AUTH}resetPassword`, resetPasswordData);
        console.log('Request headers:', axios.defaults.headers);

        return response.data; // Assuming response.data contains a 'succeeded' property
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message || 'Password reset failed');
        }
        throw error; // Throw the original error if it's not an AxiosError
    }
};


// New function to get the welcome checklist
const getWelcomeChecklist = async (onboardingToken: string) => {
  try {
    const response = await axiosWithInterceptor.get(
      `${SERVER_BASE_URL}${SERVER_API_AUTH}welcome-checklist`,
      {
        params: { onboardingToken: encodeURIComponent(onboardingToken) },
      }
    );
    return { checklist: response.data, error: null };
  } catch (error) {
    console.error("Error fetching welcome checklist:", error);

    if (axios.isAxiosError(error)) {
      if (error.response) {
        return { checklist: null, error: error.response.data };
      } else {
        return { checklist: null, error: "An unknown error occurred." };
      }
    } else {
      return { checklist: null, error: "An unexpected error occurred." };
    }
  }
};

// Function to set a new password for the user using the onboarding token
const setPassword = async (onboardingToken: string, newPassword: string, confirmPassword: string) => {
  try {
    const response = await axiosWithInterceptor.post(
      `${SERVER_BASE_URL}${SERVER_API_AUTH}set-password`,
      {
        newPassword,
        confirmPassword,
      },
      {
        params: { onboardingToken: encodeURIComponent(onboardingToken) },
      }
    );
    return { statusMessage: response.data, error: null };
  } catch (error) {
    console.error("Error setting password:", error);

    if (axios.isAxiosError(error)) {
      if (error.response) {
        return { statusMessage: null, error: error.response.data };
      } else {
        return { statusMessage: null, error: "An unknown error occurred." };
      }
    } else {
      return { statusMessage: null, error: "An unexpected error occurred." };
    }
  }
};


export const authService = {
    loginViaAuthService,
    logout,
    getToken,
    register,
    confirmEmail,
    resetPasswordEmail,
    resetPassword,
    getWelcomeChecklist,
    setPassword
//loginWith2fa
};