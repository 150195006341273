import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import useCalendar from '../hooks/useCalendar';
import useFetchHolidays from '../hooks/useFetchHolidays';
import HolidayList from './HolidayList';
import MonthGrid from './MonthGrid';
import PoweredByRibbon from '../../../htmlBlocks/PoweredByRibbon';
import { EpoMonth } from '../models/EpoMonth';

const EpoCalendar: React.FC = () => {
    const [month1, setMonth1] = useState<EpoMonth>({ name: 'January', days: [] }); // Initialize with your data structure
    const [year1, setYear1] = useState<number>(2023);
    const [month2, setMonth2] = useState<EpoMonth>({ name: 'February', days: [] }); // Initialize with your data structure
    const [year2, setYear2] = useState<number>(2023);
    const months = useCalendar();
    const now = new Date();
    const initialMonthIndex = (now.getFullYear() - 2023) * 12 + now.getMonth();
    const [currentMonthIndex, setCurrentMonthIndex] = useState(initialMonthIndex);

    const { holidays, loading, error } = useFetchHolidays(currentMonthIndex);

    useEffect(() => {
        if (months.length > 0) {
            const newMonth1 = months[currentMonthIndex];
            const newYear1 = new Date(newMonth1.days[15].date).getFullYear();
            const newMonth2 = months[currentMonthIndex + 1];
            const newYear2 = new Date(newMonth2.days[15].date).getFullYear();
            setMonth1(newMonth1);
            setYear1(newYear1);
            setMonth2(newMonth2);
            setYear2(newYear2);
        }
    }, [currentMonthIndex, months]);

    const handlePreviousMonth = () => {
        setCurrentMonthIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    };

    const handleNextMonth = () => {
        setCurrentMonthIndex((prevIndex) =>
            prevIndex < months.length - 2 ? prevIndex + 1 : prevIndex
        );
    };

    return (
        <div className="h-10 relative mx-auto max-w-screen-md text-center mt-2">
            <div className="w-full p-16 bg-white dark:bg-gray-900 rounded-lg shadow-md">
                <PoweredByRibbon />
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white mb-12">
                    EPO Closing Days 2023, 2024, and 2025*
                </h1>
                <div className="relative grid grid-cols-1 gap-x-14 md:grid-cols-2 gap-y-14">
                    <button
                        type="button"
                        className="absolute -left-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 dark:text-white"
                        onClick={handlePreviousMonth}
                        disabled={currentMonthIndex === 0}
                    >
                        <span className="sr-only dark:text-white">Previous month</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>

                    <button
                        type="button"
                        className="absolute -right-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 dark:text-white"
                        onClick={handleNextMonth}
                        disabled={currentMonthIndex >= months.length - 2}
                    >
                        <span className="sr-only dark:text-white">Next month</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    {months.slice(currentMonthIndex, currentMonthIndex + 2).map((month, monthIdx) => {
                        const year = new Date(month.days[15].date).getFullYear();
                        return (
                            <MonthGrid
                                key={`${month.name}-${year}-${monthIdx}`}
                                month={month}
                                year={year}
                                holidays={holidays}
                            />
                        );
                    })}
                </div>
                <HolidayList
                    holidays={holidays}
                    loading={loading}
                    error={error}
                    month1={month1.name}
                    year1={year1}
                    month2={month2.name}
                    year2={year2}
                />
                <div className="flex justify-center">
                    <p className="mt-8 text-sm text-gray-600 font-normal dark:text-gray-300 text-center">
                        *2025 EPO closing days extrapolated from previous years. Official 2025 dates not yet published.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EpoCalendar;
