// src/suite_features/epo_calendar/components/HolidayList.tsx
import React from 'react';
import { EpoHolidayDTO } from '../../../business/models/EpoHolidayInterfaces';
import { Spinner } from 'flowbite-react';

interface HolidayListProps {
  holidays: EpoHolidayDTO[];
  loading: boolean;
  error: string | null;
  month1: string;
  year1: number;
  month2: string;
  year2: number;
}

const HolidayList: React.FC<HolidayListProps> = ({ holidays, loading, error, month1, year1, month2, year2 }) => {
  if (loading) {
    return (
      <section className="mt-12">
        <Spinner
          size="md"
          color="purple"
        />
      </section>
    );
  }

  if (error) {
    return <p className="text-red-600">{error}</p>;
  }

  const groupedHolidays = holidays.reduce((acc: { [key: string]: { date: string, name: string, offices: string[] } }, holiday) => {
    const key = `${holiday.date}-${holiday.name}`;
    if (!acc[key]) {
      acc[key] = { date: holiday.date, name: holiday.name, offices: [] };
    }
    acc[key].offices.push(holiday.office);
    return acc;
  }, {});

  const groupedHolidayArray = Object.values(groupedHolidays).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return (
    <section className="mt-12 dark:text-white">
      {groupedHolidayArray.length === 0 &&
        <h2 className="pb-4 text-base font-normal leading-6 text-gray-900 dark:text-white">
          No EPO holidays between <span className='font-semibold'>{month1} {year1}</span> and <span className='font-semibold'>{month2} {year2}</span>.
        </h2>
      }
      {groupedHolidayArray.length > 0 && (
        <h2 className="pb-4 text-base font-normal leading-6 text-gray-900 dark:text-white">
          EPO holidays between <span className='font-semibold'>{month1} {year1}</span> and <span className='font-semibold'>{month2} {year2}</span>:
        </h2>
      )}
      <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
        {groupedHolidayArray.map((holiday, index) => (
          <li key={index} className="py-4 sm:flex text-left">
            <time dateTime={holiday.date} className="w-28 flex-none whitespace-nowrap mr-8 dark:text-purple-400">
              {new Date(holiday.date).toDateString()}
            </time>
            <p className="mt-2 flex-auto font-semibold text-gray-900 sm:mt-0 dark:text-white">{holiday.name}</p>
            <p className="flex-none sm:ml-6 dark:text-purple-400">
              {holiday.offices.join(', ')}
            </p>
          </li>
        ))}
      </ol>
    </section>
  );
};

export default HolidayList;
