// src/pages/HomePage.tsx
import React from 'react';
import CenteredList from '../blog/components/CenteredList';
import FamilyTreeSearch from '../business/components/FamilyTreeSearch';
// import DynamicFamilyTreeSearch from '../business/components/DynamicFamilyTreeSearch';
import useParallax from '../hooks/useParallax';
import UpgradeToPro from '../components/UpgradeToPro';

const HomePage: React.FC = () => {
    useParallax('parallax-section');

    return (
        <div>
            <section className="py-8 px-4 bg-gray-50 dark:bg-black mx-auto sm:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-3xl text-center my-32">
                    <h1 className="mt-16 mb-4 font-normal text-gray-900 dark:text-white text-5xl lg:text-6xl xl:px-36 lg:mb-7">
                        Best-In-Class Patent Family Tree <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-400 from-purple-700">
                            Visualization
                        </span> Tool
                    </h1>
                </div>
                <div className="mx-auto max-w-screen-md text-center">
                    <h2 className="mt-10 mb-8 tracking-tight font-semibold text-gray-900 dark:text-white text-2xl md:text-4xl lg:text-5xl">
                        <div className="w-full p-4">
                            <h2 className="mb-6 font-normal text-gray-900 dark:text-white text-4xl lg:text-5xl">
                                3 types of patent <span className="text-transparent bg-clip-text bg-gradient-to-r to-sky-400 from-purple-700">visualizations</span>:
                            </h2>
                            <br />
                            <h2 className="mb-6 font-normal text-gray-900 dark:text-white text-4xl lg:text-5xl">
                                <span className="text-transparent bg-clip-text bg-gradient-to-r to-orange-400 from-pink-700">Timelines | Family Trees | Graphs</span>
                            </h2>
                        </div>
                    </h2>
                    <br />
                </div>
            </section>
            <section id="parallax-section" className="relative py-8 px-4 bg-parallax bg-white dark:bg-gray-800 mx-auto sm:py-16 lg:px-6 bg-[url('https://wpcms.patalytica.com/wp-content/uploads/2024/05/darkhero-scaled.jpg')]">
                <div className="relative z-10 mx-auto max-w-screen-md text-center">
                    <div className="w-full p-16 relative z-10 bg-white dark:bg-gray-800 rounded-lg dark:my-shadow-sharp my-shadow-sharp hover:dark:border-purple-400 hover:dark:border-2">
                        <h2 className="mb-6 font-light text-gray-900 dark:text-white text-4xl lg:text-5xl">Build <span className="text-transparent bg-clip-text font-normal bg-gradient-to-r to-sky-400 from-purple-700">visualizations</span> with ease:</h2>
                        <ul className="list-disc list-inside space-y-2 tracking-normal py-4">
                            <li className="mb-3 font-light text-gray-900 dark:text-white text-2xl lg:text-3xl"><span className='font-normal'>one-click builds all:</span> any patent visualization using live patent office data.
                            </li>
                            <li className="mb-3 font-light text-gray-900 dark:text-white text-2xl lg:text-3xl"><span className='font-normal'>in-depth insights:</span> drill deep into patent data for detailed information.
                            </li>
                            <li className="mb-3 font-light text-gray-900 dark:text-white text-2xl lg:text-3xl"><span className='font-normal'>organize and save:</span> save your searches and organize queries for easy access.
                            </li>
                        </ul>
                    </div>
                    <br />
                </div>

                <div className="relative z-10 mx-auto max-w-screen-sm divide-y divide-gray-200 dark:divide-gray-700 p-4">
                    <div className='lg:mt-24'>
                        <FamilyTreeSearch />
                    </div>
                </div>
                <div className="relative z-10 mx-auto max-w-screen-sm divide-y divide-gray-200 dark:divide-gray-700 p-4 mt-8 mb-32">
                    <div className='lg:mt-24'>
                        {/* <DynamicFamilyTreeSearch /> */}
                    </div>
                </div>
            </section>

            <section className="bg-white dark:bg-gray-900 lg:pt-60">
                <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center">
                        <h2 className="mb-4 text-4xl tracking-tight font-semibold text-gray-900 dark:text-white">
                            Our Blog
                        </h2>
                        <p className="mb-10 text-xl font-normal dark:text-gray-300">
                            Use cases, tutorials, and latest developments of Patalytica products.
                        </p>
                    </div>
                    <div className="mx-auto max-w-screen-sm divide-y divide-gray-200 dark:divide-gray-700">
                        <CenteredList />
                    </div>
                </div>
            </section>
            <UpgradeToPro />
        </div>
    );
};

export default HomePage;
