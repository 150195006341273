import { useEffect, useState } from 'react';
import paymentService from '../services/paymentService';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../routes/RoutePaths';
import { stripeIDs } from '../config/config';
import { ToggleSwitch } from 'flowbite-react';
import { PriceData, PriceIntervals } from '../types/types';
import BuyTheSubscription from '../htmlBlocks/BuyTheSubscription';

const PricingBlock = () => {
    // Use the defined interfaces with useState hooks
    const [unlimitedPrices, setUnlimitedPrices] = useState<PriceIntervals>({ monthly: null, yearly: null });
    const [proPrices, setProPrices] = useState<PriceIntervals>({ monthly: null, yearly: null });
    const [selectedInterval, setSelectedInterval] = useState<'monthly' | 'yearly'>('monthly'); // Restrict type to 'monthly' or 'yearly'
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                // Fetch Unlimited Subscription Prices
                const unlimitedData = await paymentService.fetchSubscriptionPrice(stripeIDs.STRIPE_SUBSCRIPTION_UNLTD);
                if (unlimitedData && unlimitedData.length > 0) {
                    const monthlyPrice = unlimitedData.find((price: PriceData) => price.interval === 'month') || null;
                    const yearlyPrice = unlimitedData.find((price: PriceData) => price.interval === 'year') || null;
                    setUnlimitedPrices({ monthly: monthlyPrice, yearly: yearlyPrice });
                } else {
                    setError('Failed to fetch unlimited plan prices.');
                }
    
                // Fetch Pro Subscription Prices
                const proData = await paymentService.fetchSubscriptionPrice(stripeIDs.STRIPE_SUBSCRIPTION_PRO);
                if (proData && proData.length > 0) {
                    const monthlyPrice = proData.find((price: PriceData) => price.interval === 'month') || null;
                    const yearlyPrice = proData.find((price: PriceData) => price.interval === 'year') || null;
                    setProPrices({ monthly: monthlyPrice, yearly: yearlyPrice });
                } else {
                    setError('Failed to fetch pro plan prices.');
                }
            } catch (error) {
                setError('Error fetching prices. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
    
        fetchPrices();
    }, []);

    return (
        <>
        <div className="flex items-center justify-center mb-6">
            <label className="mr-2 font-bold text-gray-900 dark:text-white">Monthly</label>
            <ToggleSwitch
                checked={selectedInterval === 'yearly'}
                onChange={() => setSelectedInterval(selectedInterval === 'monthly' ? 'yearly' : 'monthly')}
                label=""
            />
            <label className="ml-2 font-bold text-gray-900 dark:text-white">Yearly</label>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-4 gap-x-16 bg-gray-50 dark:bg-gray-800 p-8 rounded-t-lg">
           
            {/* Free Plan */}
            <div className="hidden md:block"></div>
            <div className="mb-8 md:mb-0">
                <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">14-day trial version</h3>
                <span className="block mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">Free</span>
                <p className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                    Get started with a limited number of searches. Automatically upgrade to a <span className='font-bold'>Pro</span> plan when you reach the limit.
                </p>
                <Link to={RoutePaths.Register} className="block w-full px-6 py-2 font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:text-gray-100 hover:bg-gray-100 hover:text-blue-700 dark:hover:bg-gray-700 focus:outline-none focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700">
                    Get started
                </Link>
            </div>

            {/* Unlimited Plan */}
            <div className="mb-8 md:mb-0">
                <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">Unlimited</h3>
                <span className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
                    {loading ? (
                        <p>Loading price...</p>
                    ) : error ? (
                        <p>{error}</p>
                    ) : (
                        <p>
                            {unlimitedPrices[selectedInterval]?.currency.toUpperCase()} {unlimitedPrices[selectedInterval]?.amount.toFixed(2)} / {selectedInterval}
                        </p>
                    )}
                </span>
                <p className="my-4 text-sm text-gray-500 dark:text-gray-400">
                    Recommended for IP professionals and companies with a substantial interest in monitoring patent families.
                </p>
                <BuyTheSubscription 
                priceLookupKey={unlimitedPrices[selectedInterval]?.lookupKey || ''}       
                planName='Unlimited'         
                planInterval={selectedInterval} 
                />            
                </div>

            {/* Pro Plan */}
            <div className="mb-3 md:mb-0">
                <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">Pro</h3>
                <span className="block mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
                    {loading ? (
                        <p>Loading price...</p>
                    ) : error ? (
                        <p>{error}</p>
                    ) : (
                        <p>
                            {proPrices[selectedInterval]?.currency.toUpperCase()} {proPrices[selectedInterval]?.amount.toFixed(2)} / {selectedInterval}
                        </p>
                    )}
                </span>
                <p className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                    Recommended for individuals, small businesses, and startups with a limited budget.
                </p>
                <BuyTheSubscription 
                priceLookupKey={proPrices[selectedInterval]?.lookupKey || ''}   
                planName='Pro'
                planInterval={selectedInterval}               
                />
            </div>
        </div>
        </>
    );
};

export default PricingBlock;