//src/blog/components/CenteredList.tsx
import React, { useEffect, useState } from 'react';
import { blogService, BlogPostPreviewDTO } from '../../services/blogService';
import config from '../../config/config';
import { Link } from 'react-router-dom';
import { Spinner } from 'flowbite-react';
import { RoutePaths } from '../../routes/RoutePaths';

const CenteredList: React.FC = () => {
  const [blogPosts, setBlogPosts] = useState<BlogPostPreviewDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { CLIENT_BASE_URL, CLIENT_BLOG_URL } = config;


  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const posts = await blogService.fetchLatestBlogPosts();
        setBlogPosts(posts);
      } catch (err) {
        setError('Error fetching blog posts');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (isLoading) {
    return <div className='flex justify-center py-16'>
      <Spinner
        size='xl'
        color='purple'
      />
    </div>;
    ;
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (

    <div>
      {blogPosts.map((blogPost) => (
        <article key={blogPost.id} className="py-6 pb-6 text-center">
          {blogPost.categoryNames.map((categoryName, index) => (
            <Link key={index} to={RoutePaths.ListOfSameCategoryBlogPosts.replace(':categoryName', categoryName)}>
              <span className="ml-2 bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                <svg className="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z">
                  </path>
                </svg>
                {categoryName}
              </span>
            </Link>
          ))}
          <h2 className="my-4 text-2xl font-bold tracking-tight text-gray-900 md:text-3xl dark:text-white">
            <Link to={CLIENT_BASE_URL + CLIENT_BLOG_URL + blogPost.slug}>
              {blogPost.title}
            </Link>
          </h2>
          <p className="mb-4 text-lg font-normal dark:text-gray-300">
            {blogPost.excerpt}
          </p>
          <div className="flex justify-center items-center space-x-4 text-gray-500 sm:space-x-6">
            {/* <div className="flex items-center space-x-3">
              <img
                className="w-6 h-6 rounded-full"
                alt={blogPost.authorName}
                src={blogPost.authorImageUrl}
              />
              <span className="text-lg font-medium text-gray-900 dark:text-white">
                {blogPost.authorName}
              </span>
            </div> */}
            <span>
              {new Date(blogPost.date).toLocaleDateString()}
            </span>
          </div>
        </article>
      ))}
    </div>
  );
};
export default CenteredList;
