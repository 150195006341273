import React, { useEffect } from 'react';
import { EpoMonth } from '../models/EpoMonth';
import { EpoHolidayDTO } from '../../../business/models/EpoHolidayInterfaces';
import { Tooltip } from 'flowbite-react';

interface MonthGridProps {
  month: EpoMonth;
  year: number;
  holidays: EpoHolidayDTO[];
  onUpdateMonthYear?: (month: EpoMonth, year: number) => void;
}

function classNames(...classes: (string | undefined | null | boolean)[]): string {
  return classes.filter(Boolean).join(' ');
}

const MonthGrid: React.FC<MonthGridProps> = ({ month, year, holidays, onUpdateMonthYear }) => {
  useEffect(() => {
    if (onUpdateMonthYear) {
      onUpdateMonthYear(month, year);
    }
  }, [month, year, onUpdateMonthYear]);

  const getHolidayTooltip = (date: string) => {
    const dateWithoutTime = date.split('T')[0];
    const holidayInfo = holidays.filter(holiday => holiday.date.split('T')[0] === dateWithoutTime);
    if (holidayInfo.length > 0) {
      const holidayNamesSet = new Set<string>();
      const holidayOfficesSet = new Set<string>();

      holidayInfo.forEach(holiday => {
        holidayNamesSet.add(holiday.name.trim()); // Trim the holiday name
        holidayOfficesSet.add(holiday.office.trim()); // Trim the holiday office if needed
      });

      const holidayNames = Array.from(holidayNamesSet).join(', ');
      const holidayOffices = Array.from(holidayOfficesSet).join(', ');

      return (
        <>
          <h5 className='mb-2 text-lg font-semibold leading-tight text-white'>{holidayNames}</h5>
          <p>{holidayOffices}</p>
        </>
      );
    }
    return '';
  };

  const isHoliday = (date: string) => {
    const dateWithoutTime = date.split('T')[0];
    return holidays.some(holiday => holiday.date.split('T')[0] === dateWithoutTime);
  };

  const getWeekendTooltip = (date: Date) => {
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 5) {
      return 'Saturday';
    }
    if (dayOfWeek === 6) {
      return 'Sunday';
    }
    return '';
  };

  return (
    <section className="text-center">
      <h2 className="text-sm font-semibold text-gray-900 dark:text-white">{month.name} {year}</h2>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500 dark:text-purple-400">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 dark:bg-purple-400 dark:border-purple-400 text-sm shadow ring-1 ring-gray-200 dark:ring-purple-400">
        {month.days.map((day, dayIdx) => {
          const date = new Date(day.date);
          const isWeekend = date.getDay() === 5 || date.getDay() === 6;
          const holidayTooltip = getHolidayTooltip(day.date);
          const weekendTooltip = getWeekendTooltip(date);

          const buttonClass = classNames(
            day.isCurrentMonth
              ? isHoliday(day.date)
                ? 'bg-gray-400 text-gray-900 dark:bg-gray-600 dark:text-white'
                : isWeekend
                  ? 'bg-gray-400 text-gray-900 dark:bg-gray-600 dark:text-white'
                  : 'bg-white text-gray-900 dark:bg-black dark:text-gray-300'
              : 'bg-gray-50 text-gray-400 dark:bg-gray-400 dark:text-gray-600',
            dayIdx === 0 && 'rounded-tl-lg',
            dayIdx === 6 && 'rounded-tr-lg',
            dayIdx === month.days.length - 7 && 'rounded-bl-lg',
            dayIdx === month.days.length - 1 && 'rounded-br-lg',
            'relative py-1.5 hover:bg-gray-100 focus:z-10 flex justify-center items-center max-w-full w-full'
          );

          const timeClass = classNames(
            day.isToday && 'bg-indigo-600 dark:bg-green-400 font-semibold text-white ',
            'mx-auto flex h-7 w-7 items-center justify-center rounded-full'
          );

          return (
            <button
              key={day.date}
              type="button"
              className={buttonClass}
            >
              {(isHoliday(day.date) || isWeekend) ? (
                <Tooltip
                  key={day.date}
                  content={holidayTooltip || weekendTooltip}
                  placement="top"
                  trigger="hover"
                  className="z-10 min-w-max dark:bg-purple-800 dark:text-white"
                >
                  <time dateTime={day.date} className={timeClass}>
                    {day.date.split('-').pop()?.replace(/^0/, '')}
                  </time>
                </Tooltip>
              ) : (
                <time dateTime={day.date} className={timeClass}>
                  {day.date.split('-').pop()?.replace(/^0/, '')}
                </time>
              )}
            </button>
          );
        })}
      </div>
    </section>
  );
};

export default MonthGrid;